<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Servidores / {{tituloAcao}} BNG</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>

        <div class="input-group mb-3">
          
        </div>
      </div>
      <div v-if="tituloAcao" class="form-group">
        <div class="row mb-3">
          <div class="col-12">
            <p class="text-muted mb-0">Selecione o IP pelo qual o seu BNG chegará até o SGM Cloud</p>
            <label class="required">IP</label>
            <select v-model="bng.radiusIpId" class="form-control">
              <option :value="undefined" disabled>Selecione</option>
              <option v-for="ip in radiusIps" :key="ip.id" :value="ip.id">{{ip.ip}} - {{ip.description}}</option>
            </select>
            <!-- <p v-if="getRadiusIP(bng.radiusIpId)" class="small text-danger mb-0">
              Utilize essa senha no seu cliente radius: <strong>{{getRadiusIP(bng.radiusIpId).sharedSecret}}</strong>
            </p> -->
          </div>
        </div>
        <div v-show="bng.radiusIpId" class="row">
          <div class="col-12">
            <label class="custom-control custom-checkbox cursor-pointer">
              <input type="checkbox" v-model="bng.active" class="custom-control-input">
              <span class="custom-control-indicator"></span>
              <span class="custom-control-description"> Ativo</span>
            </label>
          </div>
          <div class="col-12 col-sm-6 col-lg-3">
            <label class="required">Tipo</label>
            <select v-model="bng.type" class="form-select">
              <option :value="undefined" disabled>Selecione</option>
              <option value="mikrotik">Mikrotik</option>
              <option value="accel">Accel PPP</option>
              <option value="cisco" disabled>Cisco</option>
            </select>
          </div>
          <div class="col-12 col-sm-6 col-lg-3">
            <label class="required">Descrição</label>
            <input type="text" v-model="bng.description" class="form-control">
          </div>
          <div class="col-12 col-sm-6 col-lg-3">
            <label class="required w-100">
              Identificação do cliente radius
              <Tooltip position="left" :tooltipText="'Como configurado no seu BNG'" class="float-end text-info cursor-pointer mt-1">
                <span>
                  <font-awesome-icon :icon="['fas', 'info-circle']" fixed-width  class="" />
                </span>
              </Tooltip>
            </label>
            <input type="text" v-model="bng.identifier" placeholder="" class="form-control">
          </div>
          <div class="col-6 col-sm-6 col-lg-3">
            <label class="required">Endereço IP</label>
            <input type="text" v-model="bng.ip" class="form-control">
          </div>
          <div class="col-6 col-sm-6 col-lg-3">
            <label class="required">Senha do radius</label>
            <input type="text" v-model="bng.sharedSecret" class="form-control">
          </div>
          <div class="col-6 col-sm-6 col-lg-3">
            <label>Porta de entrada</label>
            <input type="number" min="0" max="65535" v-model.number="bng.port" class="form-control">
          </div>
          <div class="col-6 col-sm-6 col-lg-3">
            <label>Capacidade</label>
            <Tooltip position="left" :tooltipText="'Para estimativas e alertas'" class="float-end text-info cursor-pointer mt-1">
              <span>
                <font-awesome-icon :icon="['fas', 'info-circle']" fixed-width  class="" />
              </span>
            </Tooltip>
            <input type="number" min="0" max="65535" v-model.number="bng.capacity" class="form-control">
          </div>
          <div class="col-12 col-sm-6 col-lg-6">
            <div class="row">
              <div class="col-12">
                <label>Pool para cliente ativo</label>
                <select v-model="bng.ipv4PoolActiveType" class="form-select">
                  <option :value="'dont_sent'">Não enviar pool para o BNG</option>
                  <option :value="'pool_name'">Enviar pool-name</option>
                  <!-- <option disabled :value="'sgm_pool'">Pool do SGM Cloud</option> -->
                </select>
              </div>
              <div v-if="bng.ipv4PoolActiveType === 'pool_name'" class="col-12 mt-1">
                <input type="text" v-model="bng.ipv4ActivePoolName" class="form-control" placeholder="pool-name-ativo">
              </div>
              <!-- <div v-if="bng.ipv4PoolActiveType === 'sgm_pool'" class="col-12">
                <label>Pool padrão acesso IPv4</label>
                <select v-model="bng.defaultIPv4Pool" class="form-select">
                  <option :value="undefined">Nenhum</option>
                  <option v-for="pool in ipPools" :key="pool.id" :value="pool.id">{{pool.description}} - {{pool.cidr}}</option>
                </select>
              </div> -->
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-6">
            <div class="row">
              <div class="col-12">
                <label>Pool para cliente atrasado</label>
                <select v-model="bng.ipv4PoolBannedType" class="form-select">
                  <option :value="'dont_connect'">Não permitir conexão</option>
                  <option :value="'dont_sent'">Não enviar pool para o BNG</option>
                  <option :value="'pool_name'">Enviar pool-name</option>
                  <!-- <option disabled :value="'sgm_pool'">Pool do SGM Cloud</option> -->
                </select>
              </div>
              <div v-if="bng.ipv4PoolBannedType === 'pool_name'" class="col-12 mt-1">
                <input type="text" v-model="bng.ipv4BannedPoolName" class="form-control" placeholder="pool-name-bloqueio">
              </div>

              <!-- <div v-if="bng.ipv4PoolBannedType === 'sgm_pool'" class="col-12">
                <label>Pool padrão bloqueio IPv4</label>
                <select v-model="bng.suspendedIPv4Pool" class="form-select">
                  <option :value="undefined">Nenhum</option>
                  <option v-for="pool in ipPools" :key="pool.id" :value="pool.id">{{pool.description}} - {{pool.cidr}}</option>
                </select>
              </div> -->
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end py-2">
          <button type="button" v-if="bng.id" @click="removeHandle()" class="btn btn-outline-danger me-2">
            <font-awesome-icon :icon="['fas', 'trash']"/>
            Excluir
          </button>
          <button type="button"
              v-if="!bng.id"
              v-on:click="createHandle()"
              v-bind:disabled="!canSave()"
              class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'check']"/>
            Cadastrar
          </button>
          <button type="button"
              v-if="!!bng.id"
              v-on:click="saveHandle()"
              v-bind:disabled="!canSave()"
              class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'check']"/>
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import Tooltip from "@/components/Tooltip.vue";

export default {
  name: 'ServidoresBNGEdit',
  components: {
    Tooltip,
  },
  data() {
    return {
      tituloAcao: '',
      id: undefined,
      radiusIps: [],
      ipPools: [],
      bng: {
        id: undefined,
        radiusIpId: undefined,
        active: true,
        type: undefined,
        description: '',
        identifier: '',
        ip: '',
        port: 3799,
        sharedSecret: '',
        capacity: 0,
        ipv4PoolActiveType: 'dont_sent',
        ipv4ActivePoolName: '',
        ipv4PoolBannedType: 'dont_sent',
        ipv4BannedPoolName: '',
        defaultIPv4Pool: undefined,
        suspendedIPv4Pool: undefined,
      },
    }
  },
  methods: {
    initNew() {
      this.tituloAcao = 'Cadastrar';
    },

    initEdit() {
      this.tituloAcao = 'Editar';

      api.get(`/isp/bngs/${this.id}`).then(res => {
        loading(false);
        this.bng = res.data;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    loadRadiusIps() {
      loading(true);

      api.get(`/isp/radius-ips?paginate.cancel&orderBy[ip]`).then(res => {
        loading(false);
        this.radiusIps = res.data.items;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    loadIpPools() {
      api.get(`/isp/pools?paginate.cancel&active=true`).then(res => {
        this.ipPools = res.data.items;
      }).catch(() => {
        setTimeout(() => {
          this.loadIpPools();
        }, 1000);
      });
    },

    getRadiusIP(id) {
      const ip = this.radiusIps.find(x => x.id === id);

      return ip;
    },

    canSave() {
      switch (true) {
        case (!this.bng.radiusIpId):
        case (!this.bng.type):
        case (this.bng.description.length < 3):
        case (this.bng.identifier.length < 3):
        case (this.bng.ip.length < 7):
        case (this.bng.sharedSecret.length < 1):
          return false;

        default:
          return true;
      }
    },

    createHandle() {
      const sendData = {...this.bng};

      loading(true);
      api.post('/isp/bngs', sendData).then(() => {
        loading(false);
        this.$router.push('/configuracoes/servidores');
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    saveHandle() {
      const sendData = {...this.bng};

      loading(true);
      api.put(`/isp/bngs`, sendData).then(() => {
        loading(false);
        this.$router.push('/configuracoes/servidores');
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    removeHandle() {
      dialogo.confirmacao(`Confirma remover o BNG <b>${this.bng.description}</b>?`).then(() => {
        loading(true);

        api.delete(`/isp/bngs/${this.bng.id}`).then(() => {
          loading(false);
          this.$router.push('/configuracoes/servidores');
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },
  },
  created() {
    switch (this.$route.name) {
      case 'ServidoresBNGNew':
        this.initNew();
        this.loadRadiusIps();
        break;

      case 'ServidoresBNGEdit':
        this.id = this.$route.params.id;
        if (!this.id) {
          this.$router.push({ path: `/configuracoes/servidores/radius-ips` });
          return;
        }

        this.initEdit();
        this.loadRadiusIps();
        this.loadIpPools();
        break;

      default: {
        this.$router.push({ path: `/configuracoes/servidores` });
      }
    }
  }
}
</script>
